'use client'

import { type ComponentPropsWithoutRef } from 'react'
import { Button, Typography } from '@shc/ui'
import NextLink from 'next/link'
import { AnalyticsLink } from '@components/analytics'
import LocationHours from '@app/[locale]/(main)/locations/_components/location-hours'
import LocationSaveaSpotSummary from '@app/[locale]/(main)/locations/_components/location-save-a-spot-summary'
import SearchLocationWaitTimes from '@app/[locale]/(main)/search/_components/search-location-wait-times'
import { WaitTimesStatusEnum } from '@app/[locale]/(main)/search/_utils/constants'
import { type SearchNamespace } from '@app/[locale]/(main)/search/_components/search-session'
import ResponsiveImage from '@components/responsive-image'
import type {
  TypePlacePublic,
  TypeScheduleAmended,
  TypeScheduleException,
} from '@lib/location-types'
import { useBuildSearchContext } from '@lib/analytics'
import { formatInTimeZone } from 'date-fns-tz'
import {
  convertToDisplayReadySchedule,
  getScheduleSummaryInfo,
} from '@lib/utilities/location-hours-utilities'
import { isInternalHref } from '@lib/mappers/menu-item-mapper'
import { type SPContext } from '@hooks/use-analytics'
import { type SendEventForHits } from 'instantsearch.js/es/lib/utils'
import { type Hit } from 'instantsearch.js'
import { getLinkForLocation } from '@lib/utilities/location-utilities'

export type TypePlaceHit = {
  wait_time: string | null
  wait_time_status: WaitTimesStatusEnum | null
  first_available_spot: number | null
} & TypePlacePublic

export interface LocationSearchResultProps extends ComponentPropsWithoutRef<'article'> {
  hit: Hit<TypePlaceHit>
  sendEvent: SendEventForHits
}

const LocationSearchResult = ({ hit, sendEvent }: LocationSearchResultProps) => {
  const {
    name,
    description,
    formatted_address: address,
    phone,
    phone_formatted,
    id: placeId,
    slug,
    schedules: schedule,
    schedule_notes: scheduleNotes,
    schedule_exceptions: scheduleExceptions,
    wait_time: waitTime,
    wait_time_status: waitTimeStatus,
    scheduling_status: schedulingStatus,
    first_available_spot: firstAvailableSpot,
    scheduling_type: schedulingType,
    scheduling_cta_label: scheduleCtaLabel,
    scheduling_cta_url: schedulingCtaUrl,
    photo_url: photoUrl,
    place_type_name: placeType,
    contains_places: containsPlaces,
    url: placeUrl,
  } = hit

  const currentPacificTime = formatInTimeZone(
    new Date(),
    'America/Los_Angeles',
    'yyyy-MM-dd HH:mm:ss'
  )
  const displayReadySchedule = convertToDisplayReadySchedule(
    currentPacificTime,
    hit.schedules as TypeScheduleAmended[],
    hit.schedule_exceptions as TypeScheduleException[]
  )
  const locationHoursSummary = getScheduleSummaryInfo(currentPacificTime, displayReadySchedule)

  const isWaitTimeAvailable = () => {
    if (!waitTimeStatus) return false

    const waitTimeAvailable = [
      WaitTimesStatusEnum.Normal,
      WaitTimesStatusEnum.HighVolume,
      WaitTimesStatusEnum.SoftClosure,
    ].includes(waitTimeStatus)

    const isLocationOpen =
      locationHoursSummary.operationalStatus === 'Open' ||
      locationHoursSummary.operationalStatus === 'Open 24 hours'

    return isLocationOpen && !!waitTime && waitTimeAvailable
  }

  const link = getLinkForLocation(slug) ?? placeUrl ?? ''

  const locationPhotoUrl = photoUrl?.includes('images.ctfassets.net')
    ? photoUrl?.replace(/^https?:/i, '')
    : ''

  const imageInfo = {
    altTag: name,
    urlString: locationPhotoUrl,
  }

  // Analytics tracking vars
  const snowplowSearchContext = useBuildSearchContext()
  const objectId = placeId?.toString() ?? '-1'
  const namespace: SearchNamespace = 'places'
  const snowplowContexts: SPContext[] = [
    {
      name: 'place',
      data: {
        place_name: name ?? '',
        place_id: +objectId,
      },
    },
    snowplowSearchContext,
  ]

  return (
    <article>
      <div className="flex md:gap-5 lg:gap-15 items-center justify-between">
        <div className="flex flex-col gap-3 justify-between">
          {name && link && (
            <Typography variant="h4" as="h2">
              <AnalyticsLink
                asPassthru={NextLink}
                href={link}
                noUnderline
                className="hover:underline"
                target={!slug && placeUrl ? '_blank' : undefined}
                snowplow={{ contexts: snowplowContexts }}
                algolia={{ click: { namespace, hit, sendEvent } }}>
                {name}
              </AnalyticsLink>
            </Typography>
          )}
          {name && !link && (
            <Typography as="h2" variant="h4">
              {name}
            </Typography>
          )}
          {description && <div>{description}</div>}
          {schedule && schedule.length > 0 && (
            <LocationHours
              now={currentPacificTime}
              schedule={schedule}
              scheduleNotes={scheduleNotes}
              scheduleExceptions={scheduleExceptions}
              summaryOnly={true}
            />
          )}
          {address && (
            <address className="not-italic">
              <div>{address.replace(/\n/g, ', ')}</div>
              <AnalyticsLink
                href={`https://google.com/maps/search/?api=1&query=${name}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Get directions"
                noUnderline
                snowplow={{ contexts: snowplowContexts }}
                algolia={{ conversion: { namespace, objectId } }}>
                Get directions
              </AnalyticsLink>
            </address>
          )}
          {placeType?.toLowerCase().includes('hospital') ? (
            <div>
              <AnalyticsLink
                noUnderline
                href={`tel:${phone}`}
                snowplow={{ contexts: snowplowContexts }}
                algolia={{ conversion: { namespace, objectId } }}>
                {phone_formatted}
              </AnalyticsLink>
            </div>
          ) : (
            <>
              {phone && !containsPlaces?.[0] && (
                <div>
                  <AnalyticsLink
                    noUnderline
                    href={`tel:${phone}`}
                    snowplow={{ contexts: snowplowContexts }}
                    algolia={{ conversion: { namespace, objectId } }}>
                    {phone_formatted}
                  </AnalyticsLink>
                </div>
              )}
              {containsPlaces?.[0] && (
                <div>
                  <AnalyticsLink
                    noUnderline
                    asPassthru={NextLink}
                    href={`${link}#departments`}
                    snowplow={{ contexts: snowplowContexts }}>
                    View phone directory
                  </AnalyticsLink>
                </div>
              )}
            </>
          )}
          {isWaitTimeAvailable() && <SearchLocationWaitTimes waitTime={waitTime} />}

          {schedulingStatus === 'enabled' && (
            <>
              {/* If firstAvailableSpot strores a valid date, Algolia returns it as non-zero Unix epoch time  */}
              {!!firstAvailableSpot && <LocationSaveaSpotSummary timeslot={firstAvailableSpot} />}

              {/* Display button for save a spot, lab appointment, or on my way */}
              {/* firstAvailableSpot === 0 corresponds to an error state, in which case we still want to show scheduling button */}

              {(firstAvailableSpot ||
                firstAvailableSpot === 0 ||
                (schedulingCtaUrl && schedulingType?.toLowerCase() === 'cta only')) && (
                <AnalyticsLink
                  as={Button}
                  href={schedulingCtaUrl ?? ''}
                  variant="outlined"
                  size="sm"
                  width="full"
                  target={isInternalHref(schedulingCtaUrl ?? '') ? undefined : '_blank'}
                  rel={isInternalHref(schedulingCtaUrl ?? '') ? undefined : 'noopener noreferrer'}
                  className="w-full md:w-fit mt-1"
                  data-testid="cta-button"
                  snowplow={{ contexts: snowplowContexts }}
                  algolia={{ conversion: { namespace, objectId } }}>
                  {scheduleCtaLabel}
                </AnalyticsLink>
              )}
            </>
          )}
        </div>
        <div className="flex flex-col justify-end items-center">
          {locationPhotoUrl && !link && (
            <ResponsiveImage
              image={imageInfo}
              focalPoint="center"
              columnWidthMax="100%"
              className="md:w-[288px] md:h-[162px] lg:w-[335px] lg:h-[188px] rounded"
            />
          )}
          {locationPhotoUrl && link && (
            <AnalyticsLink
              asPassthru={NextLink}
              href={link}
              className="group rounded overflow-hidden block"
              snowplow={{ contexts: snowplowContexts }}
              algolia={{ click: { namespace, hit, sendEvent } }}>
              <ResponsiveImage
                image={imageInfo}
                focalPoint="center"
                columnWidthMax="100%"
                className="md:w-[288px] md:h-[162px] lg:w-[335px] lg:h-[188px] rounded scale-100 duration-200 group-hover:scale-105 ease-in-out"
              />
            </AnalyticsLink>
          )}
        </div>
      </div>
    </article>
  )
}

export default LocationSearchResult
